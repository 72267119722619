:root{
    --bg-prime: rgb(42, 42, 78);
    --color-prime: rgb(233, 233, 233);
}

.header{
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--bg-prime);
    color: var(--color-prime);
    z-index: 999;
}

.nav-item{
    width: 100%;
    & a{
        color: var(--color-prime);
        width: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        transition: opacity 200ms ease;
        & svg{
            height: 1.5rem;
            width: 1.5rem;
        }
        &:hover{
            opacity: .7;
        }
    }
}

.active{
    font-weight: 900;
    & a{
        font-weight: 900;
    }
}

.logo{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    cursor: default;
}

.navbar{
    list-style: none;
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: space-around;
    transition: transform 200ms ease;
}

.menu-button{
   display: none;
}

@media screen and (max-width: 466px){
    .logo{
        font-size: .7rem;
    }
}

@media screen and (max-width: 750px){
    .header{
        padding: 1rem 0;
    }
    .navbar{
        height: 100%;
        width: 100%;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 2rem;
        right: 0;
        transform: translateY(-200%);
        z-index: 998;
        background-color: var(--bg-prime);
        border-bottom-left-radius: .5rem;
        border-bottom-right-radius: .5rem;
        opacity: 0;
        transition: transform 200ms ease, opacity 600ms ease-in, opacity 50ms ease-out;
    }
    .get-menu{
        transition: transform 200ms ease, opacity 300ms ease-in, opacity 50ms ease-out;
        transform: translateX(0);
        opacity: 1;
    }
    .menu-button{
        all: unset;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        transition: transform 200ms ease;
    }
    .rotate{
        transition: transform 200ms ease;
        transform: rotate(-90deg);
    }
}