$bg-prime: rgb(42, 42, 78);
$bg-sc: rgb(68, 68, 121);
$text: rgb(199, 199, 199);
$fb: #4267B2;
$git: #171515;
$discord: #5865F2;

form{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 0;

    & *{
        padding: 0;
        margin: 0;
    }
    & div{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    & div * {
        all: unset;
        padding: 0;
        margin: 0;
        width: 40%;
        border-radius: 1rem;
    }
    & div input:not(input[type="submit"]), & div textarea{
        background-color: #eee;
        padding: .2rem .5rem;
        color: #171515;
    }
    & div textarea{
        resize: none; 
        height: 600px;
        width: 40%;
    }
    & div label{
        color: $text;
    }
    & div input[type="submit"]{
        background-color: $bg-sc;
        padding: 1rem .5rem;
        color: $text;
        text-align: center;
        opacity: 1;
        transition: opacity 200ms ease;

        &:hover{
            opacity: .7;
        }
    }

}

.wrapper{
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.contact{
    background-color: $bg-prime;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    & .email-me{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem 0 0 2rem;
        margin: 0;
        color: white;
        text-decoration: underline;
        cursor: default;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
    }

    & .socialmedia{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        color: white;
    }
}

.other-contacts{
    width: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: .5rem;

    & .other-link{
        font-size: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        & .facebook{
            color: #4267B2;
        }

        & .github-other{
            color: $git;
        }

        & .discord{
            color: $discord;
        }

        & a{
            transition: opacity 200ms ease;
        }

        & a:hover{
            opacity: .7;
        }
    }
}

.other-contacts{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 0 2rem 0;
}
.other-link{
    & a{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & a .text{
        font-size: 1rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
}

.send{
    cursor: pointer;
    &:active{
        opacity: .7;
    }
}

.text{
    display: none;
}

.succ{
    display: block;
    color: #0a0;
}

.err{
    display: block;
    color: #a00;
}

@media screen and (max-width: 750px) {
    .contact{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .email-me{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;
        }
    }

    form{
        width: 100%;
        & div * {
            all: unset;
            padding: 0;
            margin: 0;
            width: 80%;
            border-radius: 1rem;
        }
        & div textarea{
            resize: none; 
            height: 600px;
            width: 80%;
        }
    }
}

@media screen and (max-width: 1000px) {
    .contact{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & .email-me{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1rem;
        }
    }
}

@media screen and (max-width: 1200px) {
    form{
        & div * {
            all: unset;
            padding: 0;
            margin: 0;
            width: 60%;
            border-radius: 1rem;
        }
        & div textarea{
            resize: none; 
            height: 600px;
            width: 60%;
        }
    }
    
}