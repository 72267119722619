$bg: rgb(55, 82, 158);
$bg-prime: rgb(42, 42, 78);
$color: #fff;
$padding: 2rem;

:root{
    --duration: 1000ms;
    --delay: 500ms;
}

.main{
    width: 100%;
    height: 100vh;
    padding-top: $padding; 
    overflow-y: auto;
    overflow-x: hidden;
    perspective: 10px;
    scroll-behavior: smooth;
}

.section{
    padding-top: $padding;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home{
    background-image: url(./assets/retro-4237850_1920.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: $color;
    font-size: 4rem;
    font-weight: 700;
    margin: 0;
    padding: 1rem;
    
    transform-style: preserve-3d;
    z-index: -1;
    position: absolute;
    object-fit: cover;
    transform: translateZ(-10px) scale(2);
    height: 100%;
    width: 100%;
    
    & span{
        --total: calc(var(--duration) + var(--delay));
        position: relative;
        display: block;
        color: transparent;
        overflow: hidden;
        animation: revealText 1s var(--total) forwards;
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: scaleX(0);
            transform-origin: left;
            background: $bg-prime;
            animation: rollIn var(--duration) var(--delay) forwards,
            rollOut var(--duration) var(--total) forwards;
        }
        &::after{
            border-top: 3px solid $bg;
        }
    }
}

@keyframes revealText {
    to {
        color: $color;
    }
}
@keyframes rollIn {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}
@keyframes rollOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(105%);
    }
}